.cookie-banner-container {
  position: fixed;
  bottom: 50px;
  right: 2em;
  display: flex;
  opacity: 0;
  z-index: -1;
  animation: fadeInAnimationDelayed ease 8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.alt-message {
  animation: fadeInAnimation ease 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  min-width: 220px;
  min-height: 120px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.alt-message > p {
  text-align: center;
}

.fade-out {
  animation: fadeOutAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 1;
}

.thought {
  display: flex;
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;
  min-width: 40px;
  max-width: 315px;
  min-height: 40px;
  margin: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  right: 12px;
  bottom: 25px;
  -webkit-box-shadow: 0px 0px 14px -4px #fff;
  box-shadow: 0px 0px 14px -4px #fff;
}

.thought:before,
.thought:after {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: -1;
}

.thought:after {
  bottom: 0px;
  right: 26px;
  width: 30px;
  height: 30px;
  box-shadow: 40px -24px 0 0 #fff, 65px -5px 0 -5px #fff, 85px 10px 0 -10px #fff;
}

.purple-text {
  cursor: pointer;
  color: #e14eca;
  transition: 0.3s;
}

.purple-text:hover {
  color: #ba54f5;
}

.text-black {
  color: black !important;
}

.align-left {
  text-align: left;
}

.cookie-robot {
  align-self: flex-end;
  bottom: -67px;
  position: relative;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    z-index: 1033;
  }
}

@keyframes fadeInAnimationDelayed {
  0% {
    opacity: 0;
  }

  90% {
    z-index: -1;
    display: flex;
    opacity: 0;
  }

  100% {
    opacity: 1;
    z-index: 1033;
  }
}

@keyframes fadeOutAnimation {
  0% {
    z-index: 1033;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
